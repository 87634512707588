import * as types from "../constants/columnSearchConstants";

export const searchTypes = {
  and: "and",
  or: "or"
};

const initialState = {
  searchQuery: {
    search: {},
    search_type: searchTypes.and
  },
  lastUpdatedField: ""
};

/* eslint-disable default-case, no-param-reassign */
export const columnSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_SEARCH_VALUE: {
      const field = action.data?.field;
      const searchValue = action.data?.value;
      const search = {
        ...state.searchQuery.search,
        [field]: searchValue?.trimStart()
      };

      if (!searchValue) {
        delete search[field];
      }

      return {
        ...state,
        searchQuery: { ...state.searchQuery, search },
        lastUpdatedField: field
      };
    }
    case types.CLEAR_SEARCH_VALUE: {
      const field = action.data?.field;
      const { search } = state.searchQuery;
      delete search[field];
      return { ...state, searchQuery: { ...state.searchQuery, search } };
    }

    case types.CLEAR_ALL_VALUE: {
      return initialState;
    }

    default:
      return state;
  }
};
