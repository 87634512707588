import produce from "immer";
import { STORAGE_PERMISSIONS_KEY, STORAGE_USER_KEY } from "../../utils/constants";
import { CREATE_USER_SUCCESS, SET_ALL_PERMISSIONS } from "../constants/authConstants";

const localUser = localStorage.getItem(STORAGE_USER_KEY);
const localPermissions = localStorage.getItem(STORAGE_PERMISSIONS_KEY);

export const AuthState = {
  user: JSON.parse(localUser || "null"),
  permissions: JSON.parse(localPermissions || "{}")
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = AuthState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case CREATE_USER_SUCCESS:
        localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(action.key));
        draft.user = action.key;
        break;

      case SET_ALL_PERMISSIONS:
        localStorage.setItem(STORAGE_PERMISSIONS_KEY, JSON.stringify(action.permissions));
        draft.permissions = action.permissions;
        break;

      default:
        break;
    }
  });

export default authReducer;
