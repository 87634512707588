import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Login } from "./containers/pageListAsync";
import Loader from "./components/Loading";
import Application from "./Application";
import { ToastProvider } from "./ToastContext";

const publicRouteUrl = ["/login"];

function App() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (!publicRouteUrl.includes(location.pathname)) {
      document.title = t("tabTitle");
    }
  }, [location.pathname, t]);

  return (
    <Suspense fallback={<Loader />}>
      <ToastProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Application />} />
        </Routes>
      </ToastProvider>
    </Suspense>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
