export const ApiUrl = {
  changePassword: "/me/change/password",
  login: "/auth/login",
  logout: "/auth/logout",
  me: "/me",
  getMe: "/auth/me",
  putInfo: "/auth/info",
  category: "/reference/categories",
  categoryTree: "/reference/categories/tree",
  users: "/reference/users",
  customers: "/reference/customers",
  branch: "/reference/dealers",
  role: "/reference/roles",
  passwordReset: "/auth/password/reset",
  resendSMS: "/auth/resend_sms",
  passwordCheckCode: "/auth/password/check-code",
  passwordUpdate: "/auth/password/update",
  userDevice: "/user_devices",
  restoreCategory: "/category/restore",
  products: "/reference/products",
  measures: "/reference/measures",
  terminals: "/reference/acquiring_terminals",
  brands: "/reference/brands",
  cachboxes: "/reference/cashboxes",
  currencies: "/reference/currencies",
  countries: "/reference/countries",
  regions: "/reference/regions",
  districts: "reference/districts",
  warehouses: "/reference/warehouses",
  price_types: "/reference/price_types",
  permission: "/reference/permissions",
  cachboxes_ccm: "/reference/cashbox_ccm",
  current_stock: "/stock/current_stock",
  sale_orders: "/sale/sales_orders",
  productImageUpload: "/reference/attachments",
  payments: "/cash/payments",
  limit: "/reference/dealers/limit",
  active_orders: "/reference/dealers/active_orders"
};

export const queryKeys = {
  current_stock: ApiUrl.current_stock,
  sale_orders: `${ApiUrl.sale_orders}/get`,
  brands: `${ApiUrl.brands}/get`,
  cachboxes_ccm: `${ApiUrl.cachboxes_ccm}/get`,
  price_types: `${ApiUrl.price_types}/get`,
  measures: `${ApiUrl.measures}/get`,
  cachboxes: `${ApiUrl.cachboxes}/get`,
  terminals: `${ApiUrl.terminals}/get`,
  categories: `${ApiUrl.category}/get`,
  categoriesTree: `${ApiUrl.categoryTree}/get`,
  branch: `${ApiUrl.branch}/get`,
  users: `${ApiUrl.users}/get`,
  customers: `${ApiUrl.customers}/get`,
  branches: `${ApiUrl.branch}/get`,
  limit: `${ApiUrl.limit}/get`,
  active_orders: `${ApiUrl.limit}/get`,
  countries: `${ApiUrl.countries}/get`,
  currencies: `${ApiUrl.currencies}/get`,
  districts: `${ApiUrl.districts}/get`,
  jobById: `${ApiUrl.job}/get/id`,
  organizations: `${ApiUrl.organization}/get`,
  organization: `${ApiUrl.organization}/by/id`,
  partners: `${ApiUrl.partner}/get`,
  partnerById: `${ApiUrl.partner}/get/id`,
  priceTypes: `${ApiUrl.priceType}/get`,
  products: `${ApiUrl.products}/get`,
  regions: `${ApiUrl.regions}/get`,
  warehouses: `${ApiUrl.warehouses}/get`,
  variations: `${ApiUrl.variation}/get`,
  reason_groups: `${ApiUrl.reason_group}/get`,
  specifications: `${ApiUrl.specifications}/get`,
  specificationGroup: `${ApiUrl.specificationGroup}/get`,
  pos_users: `${ApiUrl.posUser}/get`,
  setting: `${ApiUrl.setting}/get`,
  registerBanks: `${ApiUrl.registerBank}/get`,
  registerEquirings: `${ApiUrl.registerEquiring}/get`,
  productBarcodes: `${ApiUrl.productBarcodes}/get`,
  productBarcodesDelete: `${ApiUrl.productBarcodes}/delete`,
  productIntegrators: `${ApiUrl.productIntegrators}/get`,
  registerPrices: `${ApiUrl.registerPrice}/get`,
  bonusIMEIs: `${ApiUrl.bonusIMEI}/get`,
  saleProductList: `${ApiUrl.saleProductList}`,
  stockProduct: `${ApiUrl.stockProduct}`,
  currencyByDate: `${ApiUrl.currencyByDate}`,
  bonusSchemes: `${ApiUrl.bonusScheme}/get`,
  bonusScheme: `${ApiUrl.bonusScheme}/getById`,
  employees: `${ApiUrl.employee}/get`,
  IMEIUploadsTrackings: `${ApiUrl.IMEIUploadsTracking}`,
  CSVUploadsTrackings: `${ApiUrl.CSVUploadsTracking}`,
  salesParty: `${ApiUrl.salesParty}`,
  registeredIMEIs: `${ApiUrl.registeredIMEI}`,
  registerBonuses: `${ApiUrl.registerBonus}`,
  paymentAndFines: `${ApiUrl.paymentAndFine}`,
  roles: `${ApiUrl.role}/get`,
  permissions: `${ApiUrl.permission}/get`,
  userDevice: `${ApiUrl.userDevice}/by/id`
};
