// export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
// export const DAEMON = "@@saga-injector/daemon";
// export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
export const STORAGE_USER_KEY = "user";
export const STORAGE_PERMISSIONS_KEY = "permissions";
// export const STORAGE_LANGUAGE_KEY = "i18nextLng";

// export const ROW_SIZE = 35;
// export const HEADER_SIZE = 50;

// export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
// export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
// export const DATE_FORMAT_EN = "DD-MM-YYYY";
// export const DATE_FORMAT_MONTH_NAME = "MMMM YYYY";
// export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
// export const visibleMenus = ["my-notification"];
// export const DEFAULT_LATITUDE = 41.3775;
// export const DEFAULT_LONGITUDE = 64.5853;

export const STORAGE_LANGUAGE_KEY = "i18nextLng";
