import React from "react";
import Loading from "../components/Loading";
import loadable from "../utils/loadable";

export const Login = loadable(() => import("../containers/Pages/Login/Login"), {
  fallback: <Loading />
});

export const Layout = loadable(() => import("../components/Layout/layout"), {
  fallback: <Loading />
});

export const WelcomePage = loadable(() => import("../containers/Pages/WelcomePage/WelcomePage"), {
  fallback: <Loading />
});

export const UserRoles = loadable(() => import("../containers/Pages/Roles/UserRoles"), {
  fallback: <Loading />
});

export const CreateUpdateRole = loadable(() => import("../containers/Pages/Roles/CreateUpdateRole"), {
  fallback: <Loading />
});

export const Categories = loadable(() => import("../containers/Pages/Categories/Categories"), {
  fallback: <Loading />
});

export const CreateUpdateCategory = loadable(() => import("../containers/Pages/Categories/CreateUpdateCategory"), {
  fallback: <Loading />
});

export const Branches = loadable(() => import("./Pages/Dealers/Branches"), {
  fallback: <Loading />
});

export const CreateUpdateBranch = loadable(() => import("./Pages/Dealers/CreateUpdateBranch"), {
  fallback: <Loading />
});

export const NotFound = loadable(() => import("../containers/NotFound/NotFound"), {
  fallback: <Loading />
});

export const Users = loadable(() => import("../containers/Pages/Users/Users"), {
  fallback: <Loading />
});

export const Products = loadable(() => import("../containers/Pages/Products/Products"), {
  fallback: <Loading />
});

export const Measures = loadable(() => import("../containers/Pages/Measures/Measures"), {
  fallback: <Loading />
});

export const Terminals = loadable(() => import("../containers/Pages/Terminals/Terminals"), {
  fallback: <Loading />
});

export const Brands = loadable(() => import("../containers/Pages/Brands/Brands"), {
  fallback: <Loading />
});

export const Cashboxes = loadable(() => import("../containers/Pages/Cashboxes/Cashboxes"), {
  fallback: <Loading />
});

export const CashboxCCM = loadable(() => import("../containers/Pages/CashboxCCM/CashboxCCM"), {
  fallback: <Loading />
});

export const Currencies = loadable(() => import("../containers/Pages/Currencies/Currencies"), {
  fallback: <Loading />
});

export const Payments = loadable(() => import("../containers/Pages/Payments/Payments"), {
  fallback: <Loading />
});

export const Countries = loadable(() => import("../containers/Pages/Countries/Countries"), {
  fallback: <Loading />
});

export const Regions = loadable(() => import("../containers/Pages/Regions/Regions"), {
  fallback: <Loading />
});

export const Districts = loadable(() => import("../containers/Pages/Districts/Districts"), {
  fallback: <Loading />
});

export const Warehouses = loadable(() => import("../containers/Pages/Warehouses/Warehouses"), {
  fallback: <Loading />
});

export const PriceTypes = loadable(() => import("../containers/Pages/PriceTypes/PriceTypes"), {
  fallback: <Loading />
});

export const StockOrders = loadable(() => import("../containers/Pages/StockOrders/StockOrders"), {
  fallback: <Loading />
});

export const Customers = loadable(() => import("../containers/Pages/Customers/Customers"), {
  fallback: <Loading />
});

export const CreateUpdateStockOrder = loadable(() => import("../containers/Pages/StockOrders/CreateUpdateStockOrder"), {
  fallback: <Loading />
});

export const AppVersionNotCompatible = loadable(() => import("../containers/NotFound/AppVersionNotCompatible"), {
  fallback: <Loading />
});
