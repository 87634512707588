import axios from "axios";
import Cookie from "js-cookie";
import { STORAGE_LANGUAGE_KEY } from "../utils/constants";
import { defaultLanguage } from "../constant/default";
import { accessTokenName } from "../constant/index";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "https://testapi.bmel.uz/api/v1",
  // baseURL: "https://api.bmel.uz/api/v1",
  timeout: 300_000,
  headers: {
    Authorization: `Bearer ${Cookie.get(accessTokenName)}`,
    "Accept-Language": localStorage.getItem(STORAGE_LANGUAGE_KEY) ?? defaultLanguage,
    "App-Version": process.env.REACT_APP_VERSION,
    "App-Type": "web_erp"
  }
};

// const tasnifConfig = {
//   baseURL: process.env.REACT_APP_API_TASNIF_URL,
//   timeout: 300_000, // 5 minutes
// };
// const requestTax = axios.create(tasnifConfig);

const api = axios.create(config);
api.interceptors.request.use(
  c => {
    const con = c;
    con.headers.Authorization = `Bearer ${Cookie.get(accessTokenName)}`;
    con.headers["Accept-Language"] = localStorage.getItem(STORAGE_LANGUAGE_KEY) ?? defaultLanguage;
    return con;
  },
  error => Promise.reject(error)
);

export { api };
