/* eslint-disable consistent-return */
import toast from "react-hot-toast";
import { get } from "lodash";
import Cookie from "js-cookie";
import axios from "axios";
import { setJWTTokenToCookie } from "../utils/jwt";
import { accessTokenName, refreshTokenName, tokenStartTime, versionNotCompatibleUrl } from "../constant/index";
import { api } from "./client";

const getRefreshToken = async () => {
  if (Cookie.get(refreshTokenName)) {
    try {
      return await axios.post(
        process.env.REACT_APP_API_URL + "/auth/refresh",
        {},
        {
          headers: {
            Authorization: `Bearer ${Cookie.get(refreshTokenName)}`,
            "App-Type": "web_erp",
            "App-Version": process.env.REACT_APP_VERSION
          }
        }
      );
    } catch (err) {
      const redirectUrl = window.location.pathname + window.location.search;
      Cookie.remove(accessTokenName);
      Cookie.remove(refreshTokenName);
      window.location.replace(`/login?redirectUrl=${redirectUrl}`);
    }
  } else {
    const redirectUrl = window.location.pathname + window.location.search;
    window.location.replace(`/login?redirectUrl=${redirectUrl}`);
  }
};

let isFetched = false;
export const fetchData = async (url, method, data = {}, ...props) => {
  try {
    return await api[method](url, data, ...props);
  } catch (error) {
    const res = error.response;
    // if (res.status >= 500) {
    //   window.location.replace("/server-internal-error");
    // }
    if (res.status !== 401) {
      toast.error(get(error, "response.data.message"));
    }

    if (res.status === 426) {
      if (window.location.pathname !== versionNotCompatibleUrl) {
        window.location.replace(versionNotCompatibleUrl);
      }
    }

    if (res.status === 401 && !window.location.pathname.includes("/login") && res.config && !res.config.__isRetryRequest && !isFetched) {
      isFetched = true;
      return getRefreshToken().then(RefreshTokenResponse => {
        if (RefreshTokenResponse?.data?.access_token) {
          setJWTTokenToCookie(accessTokenName, RefreshTokenResponse?.data?.access_token, RefreshTokenResponse?.data?.expires_in);
        }
        if (RefreshTokenResponse?.data?.refresh_token) {
          setJWTTokenToCookie(refreshTokenName, RefreshTokenResponse?.data?.refresh_token, RefreshTokenResponse?.data?.refresh_ttl);
        }

        setJWTTokenToCookie(tokenStartTime, Date.now(), RefreshTokenResponse?.data?.refresh_ttl);

        error.config.__isRetryRequest = true;
        error.config.headers.Authorization = `Bearer ${Cookie.get(accessTokenName)}`;
        isFetched = false;
        return api(error.config);
      });
    }
    return Promise.reject(error);
  }
};
