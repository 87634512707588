import { SET_PERMISSIONS_LIST, SET_CURRENT_PERMISSIONS, SET_UPDATE_PERMISSIONS, SET_ALL_PERMISSIONS_LIST } from "../constants/roleConstants";
import { CUSTOM_PERMISSION_TYPES, RADIO_BUTTON_CONSTANTS } from "../../constant/default";

export const setUpdatePermissions = data => ({
  type: SET_UPDATE_PERMISSIONS,
  data
});

export const setCurrentPermissions = data => ({
  type: SET_CURRENT_PERMISSIONS,
  data
});

export const setPermissionsList = data => ({
  type: SET_PERMISSIONS_LIST,
  data
});

export const setAllPermissions = data => ({
  type: SET_ALL_PERMISSIONS_LIST,
  data
});

export const getDocumentPermissionValue = (accessRequirements, documentPermissions = [], hasMenuPermissions = false) => {
  const hasMenuAccess = !accessRequirements?.menu ? !accessRequirements?.menu : hasMenuPermissions;
  const hasOnlyReadAccess = documentPermissions.length === 1 && documentPermissions[0]?.name?.split(".")[1] === CUSTOM_PERMISSION_TYPES.READ;
  const hasOnlyCustomAccess = documentPermissions.length > 0;

  if (accessRequirements?.document === documentPermissions.length && hasMenuAccess && documentPermissions.some(item => !item?.name?.includes(".read"))) {
    return RADIO_BUTTON_CONSTANTS.FULL_ACCESS;
  }

  if (documentPermissions.length === 0 && hasMenuAccess) {
    return RADIO_BUTTON_CONSTANTS.FULL_ACCESS;
  }
  if (hasOnlyReadAccess) {
    return RADIO_BUTTON_CONSTANTS.ONLY_READ;
  }
  if (hasOnlyCustomAccess) {
    return RADIO_BUTTON_CONSTANTS.CUSTOM_ACCESS;
  }
  return RADIO_BUTTON_CONSTANTS.NO_ACCESS;
};
