export const language = {
  uz: "uz",
  ru: "ru"
};

export const defaultLanguage = language.ru;

export const RADIO_BUTTON_CONSTANTS = {
  FULL_ACCESS: "full_access",
  ONLY_READ: "only_read",
  NO_ACCESS: "no_access",
  CUSTOM_ACCESS: "custom_access",
  MENU: "menu"
};

export const DEFAULT_REDIRECT_MODES = {
  DUPLICATE: "duplicate",
  EDIT: "edit",
  CREATE: "create",
  VIEW: "view"
};

export const CUSTOM_PERMISSION_TYPES = {
  CREATE: "create",
  READ: "read",
  DELETE: "delete",
  UPDATE: "update"
};

export const imageFormats = [".png", ".jpg", ".jpeg", ".webp"];

export const ROWS_PER_PAGES = [50, 100, 200, 500];

export const DEFAULT_PAGE = 1;
export const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGES[1];
