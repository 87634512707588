import Cookie from "js-cookie";
import { accessTokenName } from "../constant/index";

export function parseJWT(token) {
  const base64Url = token?.split(".")[1];
  if (base64Url) {
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  return false;
}

export function verifyJWT() {
  const decodedJWT = parseJWT(Cookie.get(accessTokenName));
  let isTokenExpired = false;

  if (decodedJWT && decodedJWT?.exp && Date.now() - Number(decodedJWT?.exp) * 1000 >= 0) {
    isTokenExpired = true;
  }

  return decodedJWT && !isTokenExpired;
}

export function setJWTTokenToCookie(name, value, expireTime = 0) {
  // const timeDiffWithServer = 60 * 60 * 5; // time comes as second
  Cookie.set(name, value, { expires: expireTime / (60 * 60 * 24) });
}
