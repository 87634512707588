import { useMutation, useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import toast from "react-hot-toast";
import { fetchData } from "../api/fetchData";
import { api } from "../api/client";
import { ApiUrl } from "../networks/api-routes";

const login = async data => {
  try {
    const response = await api.post(ApiUrl.login, data);
    return response.data;
  } catch (err) {
    toast.error(get(err, "response.data.message"));
    throw err;
  }
};

export const useLogin = (params = {}) =>
  useMutation({
    mutationFn: data => login(data),
    ...params
  });

export const useInfo = (params = {}) =>
  useMutation({
    mutationFn: data => fetchData(`${ApiUrl.putInfo}`, "put", data),
    ...params
  });

export const useGetMe = (params = {}) =>
  useQuery({
    queryKey: [ApiUrl.getMe],
    queryFn: () => fetchData(ApiUrl.getMe, "get"),
    enabled: params?.enabled,
    ...params
  });

export const useGetMeMutation = (queryParams = {}) =>
  useMutation({
    mutationFn: () => fetchData(ApiUrl.getMe, "post"),
    ...queryParams
  });

export const useLogout = (queryParams = {}) =>
  useMutation({
    mutationFn: () => fetchData(ApiUrl.logout, "post"),
    ...queryParams
  });
