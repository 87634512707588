export const superAdminConst = "SUPER ADMIN";
export const accessTokenTime = "UATT";
export const accessTokenName = "UAT";
export const versionNotCompatibleUrl = "/version-not-compatible";
export const tokenStartTime = "TST";
export const refreshTokenName = "URT";
export const refreshTokenTime = "URTT";

export const superAdminAccessTokenName = "SAAT";
export const superAdminRefreshTokenName = "SART";
