import React from "react";
import HashLoader from "react-spinners/HashLoader";

function Loading() {
  return (
    <div className="main-loader">
      <HashLoader color={"#6366F1"} />
    </div>
  );
}

export default Loading;
