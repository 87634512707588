import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { isEqual } from "lodash";
import { ConfirmDialog } from "primereact/confirmdialog";
import history from "./utils/history";
import { LayoutProvider } from "./components/Layout/context/layoutcontext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./styles/style.scss";
import configureStore from "./redux/configureStore";
// eslint-disable-next-line no-unused-vars
import i18n from "./utils/i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0
    },
    mutations: {
      onMutate: async variables => {
        const maxConcurrentMutations = {
          foo: 1
        };

        const customKey = "foo";

        const currentMutationCount = queryClient.isMutating({
          predicate: mutation => isEqual(mutation.state.variables, variables),
          exact: true,
          fetching: true
        });
        if (currentMutationCount > (maxConcurrentMutations[customKey] || 1)) {
          return Promise.reject();
        }
        return null;
      }
    }
  }
});

const initialState = {};
const { store, persistor } = configureStore(initialState, history);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PrimeReactProvider>
            <LayoutProvider>
              <>
                <ConfirmDialog />
                <App />
              </>
            </LayoutProvider>
          </PrimeReactProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// Optional: Measure app performance
reportWebVitals();
