import { combineReducers } from "redux";
import authReducer from "./modules/authReducer";
import { columnSearchReducer } from "./modules/columnSearchReducer";
import { roleReducer } from "./modules/roleReducer";
import { stockReducer } from "./modules/stockReducer";

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    authReducer,
    columnSearch: columnSearchReducer,
    role: roleReducer,
    stock: stockReducer,
    ...injectedReducers
    //   outcomeOrdersUpdate: outcomeOrdersUpdateReducer,
    //   registers: registersReducer,
    //   briefingReports: briefingReportsReducer,
    //   questionReducer,
  });
}
