import produce from "immer";
import * as types from "../constants/stockConstants";

const initialState = {
  productReqData: null,
  stockOrderProducts: [],
  allProducts: []
};

/* eslint-disable default-case, no-param-reassign */
export const stockReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_STOCK_PRODUCT_REQ:
        draft.productReqData = action.data;
        break;

      case types.UPDATE_STOCK_ORDER_PRODUCTS:
        draft.stockOrderProducts = action.data;
        break;

      case types.SET_STOCK_ORDER_PRODUCTS:
        draft.stockOrderProducts = [...action.data];
        break;

      case types.SET_STOCK_ALL_PRODUCTS:
        draft.allProducts = action.data;
        break;

      default:
        break;
    }
  });
