import React from "react";
import { Route, Routes } from "react-router-dom";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useGetMe } from "./hooks/useAuth";
import { createUserSuccess } from "./redux/actions/authActions";
import { convertPermission } from "./utils/getPermissions";
import {
  Layout,
  WelcomePage,
  Categories,
  CreateUpdateCategory,
  Branches,
  CreateUpdateBranch,
  UserRoles,
  CreateUpdateRole,
  NotFound,
  Users,
  Products,
  Measures,
  Terminals,
  Brands,
  Cashboxes,
  CashboxCCM,
  Currencies,
  Countries,
  Regions,
  Districts,
  Warehouses,
  PriceTypes,
  StockOrders,
  CreateUpdateStockOrder,
  Customers,
  Payments
} from "./containers/pageListAsync";

export default function Application() {
  const dispatch = useDispatch();

  useGetMe({
    onSuccess: response => {
      let user = get(response, "data.user", null);
      const userPermissions = [];

      user?.roles?.forEach(role => userPermissions.push(...role.permissions));

      user = {
        ...user,
        permissions: convertPermission(userPermissions)
      };

      dispatch(createUserSuccess(user));
    }
  });

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/users/roles" element={<UserRoles />} />
        <Route path="/users/roles/create" element={<CreateUpdateRole />} />
        <Route path="/users/roles/edit/:id" element={<CreateUpdateRole />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/create" element={<CreateUpdateCategory />} />
        <Route path="/categories/edit/:id" element={<CreateUpdateCategory />} />
        <Route path="/dealers" element={<Branches />} />
        <Route path="/dealers/create" element={<CreateUpdateBranch />} />
        <Route path="/dealers/edit/:id" element={<CreateUpdateBranch />} />

        <Route path="/users/users" element={<Users />} />
        <Route path="/users/customers" element={<Customers />} />
        <Route path="/stock/products" element={<Products />} />
        <Route path="/stock/brands" element={<Brands />} />
        <Route path="/stock/warehouses" element={<Warehouses />} />
        <Route path="/stock/orders" element={<StockOrders />} />
        <Route path="/stock/orders/create" element={<CreateUpdateStockOrder />} />
        <Route path="/stock/orders/edit/:id" element={<CreateUpdateStockOrder />} />

        <Route path="/setting/measures" element={<Measures />} />
        <Route path="/setting/terminals" element={<Terminals />} />
        <Route path="/setting/cashboxes" element={<Cashboxes />} />
        <Route path="/setting/cashboxes_ccm" element={<CashboxCCM />} />
        <Route path="/setting/countries" element={<Countries />} />
        <Route path="/setting/regions" element={<Regions />} />
        <Route path="/setting/districts" element={<Districts />} />

        <Route path="/payment/currencies" element={<Currencies />} />
        <Route path="/payment/price_types" element={<PriceTypes />} />
        <Route path="/payment/payments" element={<Payments />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}
