import * as types from "../constants/authConstants";

//= ====================================
//   AUTH
//-------------------------------------

export const createUserSuccess = key => ({
  type: types.CREATE_USER_SUCCESS,
  key
});

export const setAllPermissions = permissions => ({
  type: types.SET_ALL_PERMISSIONS,
  permissions
});
