/* eslint-disable prettier/prettier */
import { SET_PERMISSIONS_LIST, SET_CURRENT_PERMISSIONS, SET_UPDATE_PERMISSIONS, SET_ALL_PERMISSIONS_LIST } from "../constants/roleConstants";
import { RADIO_BUTTON_CONSTANTS } from "../../constant/default";
import { getDocumentPermissionValue } from "../actions/roleActions";

export const initialState = {
  permissionsLengthObj: {
    document: 0,
    menu: false
  },
  permissionsObject: {
    // [key]: {
    //   document_permission_name: "", // Unique key
    //   document_permission_value: "",
    //   permissionType: "",
    //   document_permissions: [],
    //   roleTitle: "",
    // },
  }
};

export const roleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UPDATE_PERMISSIONS: {
      let updatedPermissionsObject = {};
      updatedPermissionsObject = {
        ...state.permissionsObject,
        ...action?.data
      };
      return { ...state, permissionsObject: updatedPermissionsObject };
    }
    case SET_PERMISSIONS_LIST: {
      const permissionsObject = {};
      const permissionsLengthObj = {};

      action?.data?.forEach(item => {
        const key = item?.name?.split(".")?.[0];
        const customPermissionLabel = item?.name?.split(".")[1];

        permissionsObject[key] = {
          ...permissionsObject[key],
          roleTitle: key,
          document_permission_name: key,
          menu_permission: {
            name: item?.name,
            value: false,
            id: item?.id
          },
          document_permission_value: RADIO_BUTTON_CONSTANTS.NO_ACCESS,
          document_permissions: permissionsObject[key]?.document_permissions
            ? [
              ...permissionsObject[key].document_permissions,
              {
                label: customPermissionLabel,
                name: item?.name,
                value: false,
                id: item?.id
              }
            ]
            : [
              {
                label: customPermissionLabel,
                name: item?.name,
                value: false,
                id: item?.id
              }
            ]
        };
      });

      Object.keys(permissionsObject).forEach(permissionKey => {
        permissionsLengthObj[permissionKey] = {
          document: permissionsObject[permissionKey]?.document_permissions?.length,
          menu: !!permissionsObject[permissionKey]?.menu_permission?.name
        };
      });

      return {
        ...state,
        permissionsLengthObj,
        permissionsObject
      };
    }

    case SET_CURRENT_PERMISSIONS: {
      const userRolesObject = {};

      action.data?.forEach(item => {
        const key = item?.name?.split(".")?.[0];
        const customPermissionLabel = item?.name?.split(".")[1];
        userRolesObject[key] = {
          ...userRolesObject[key],
          roleTitle: key,
          document_permission_name: key,
          menu_permission: {
            name: item?.name,
            value: !!item?.id,
            id: item?.id
          },
          document_permissions: userRolesObject[key]?.document_permissions
            ? [
              ...userRolesObject[key].document_permissions,
              {
                label: customPermissionLabel,
                name: item?.name,
                value: !!item?.id,
                id: item?.id
              }
            ]
            : [
              {
                label: customPermissionLabel,
                name: item?.name,
                value: !!item?.id,
                id: item?.id
              }
            ]
        };
      });

      Object.keys(userRolesObject).forEach(permissionKey => {
        if (!userRolesObject[permissionKey]) return; // Objects which has undefined values shouldn't iterate
        const accessRequirements = state?.permissionsLengthObj[permissionKey];
        const needUpdateDocumentPermissions = userRolesObject[permissionKey]?.document_permissions;
        const hasMenuPermissions = !!userRolesObject[permissionKey]?.menu_permission?.name;
        const menu_permission = userRolesObject[permissionKey]?.menu_permission ? userRolesObject[permissionKey]?.menu_permission : state?.permissionsObject[permissionKey]?.menu_permission;

        const needPermissionsTurnToObject = needUpdateDocumentPermissions?.reduce((accumulator, item) => {
          accumulator[item.name] = item;
          return accumulator;
        }, {});

        const updatedDocumentPermissions = state?.permissionsObject?.[permissionKey]?.document_permissions?.map(item => ({
          ...item,
          value: needPermissionsTurnToObject[item.name] ? true : item.value
        }));
        userRolesObject[permissionKey] = {
          ...userRolesObject[permissionKey],
          document_permissions: updatedDocumentPermissions,
          document_permission_value: getDocumentPermissionValue(accessRequirements, needUpdateDocumentPermissions, hasMenuPermissions),
          menu_permission
        };
      });

      return { ...state, permissionsObject: { ...state?.permissionsObject, ...userRolesObject } };
    }
    case SET_ALL_PERMISSIONS_LIST: {
      const newPermissionObject = {};
      if (action.data === RADIO_BUTTON_CONSTANTS.FULL_ACCESS) {
        Object.values(state?.permissionsObject)?.forEach(item => {
          const documentPermissions = item?.document_permissions?.map(documentItem => ({
            ...documentItem,
            value: true
          }));
          newPermissionObject[item?.document_permission_name] = {
            ...item,
            document_permission_value: item?.name?.includes(".read") ? RADIO_BUTTON_CONSTANTS.ONLY_READ : RADIO_BUTTON_CONSTANTS.FULL_ACCESS,
            document_permissions: documentPermissions,
            menu_permission: item?.menu_permission && {
              ...item?.menu_permission,
              value: true
            }
          };
        });
      }
      if (action.data === RADIO_BUTTON_CONSTANTS.ONLY_READ) {
        Object.values(state?.permissionsObject)?.forEach(item => {
          const documentPermissions = item?.document_permissions?.map(documentItem => {
            if (documentItem?.name?.includes(".read")) {
              return {
                ...documentItem,
                value: true
              };
            }
            return {
              ...documentItem,
              value: false
            };
          });
          newPermissionObject[item?.document_permission_name] = {
            ...item,
            document_permission_value: RADIO_BUTTON_CONSTANTS.ONLY_READ,
            document_permissions: documentPermissions,
            menu_permission: item?.menu_permission && {
              ...item?.menu_permission,
              value: false
            }
          };
        });
      }
      if (action.data === RADIO_BUTTON_CONSTANTS.NO_ACCESS) {
        Object.values(state?.permissionsObject)?.forEach(item => {
          const documentPermissions = item?.document_permissions?.map(documentItem => ({
            ...documentItem,
            value: false
          }));
          newPermissionObject[item?.document_permission_name] = {
            ...item,
            document_permission_value: RADIO_BUTTON_CONSTANTS.NO_ACCESS,
            document_permissions: documentPermissions,
            menu_permission: item?.menu_permission && {
              ...item?.menu_permission,
              value: false
            }
          };
        });
      }
      return { ...state, permissionsObject: newPermissionObject };
    }
    default:
      return state;
  }
};
