import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import uz from "../assets/locales/uz/translation.json";
import ru from "../assets/locales/ru/translation.json";
import { defaultLanguage } from "../constant/default";

const initialLanguage = localStorage.getItem("i18nextLng") ?? defaultLanguage;

const resources = {
  uz: {
    translation: uz
  },
  ru: {
    translation: ru
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: initialLanguage,
    lng: initialLanguage === "ru" || initialLanguage === "uz" ? initialLanguage : defaultLanguage,
    detection: {
      order: ["localStorage"]
    },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
